import { navigate } from 'gatsby-link';

import {
  homePageCompanyListKnownAction,
  homePageJobRequestInfoKnownAction,
} from '../reducers/consultantHomePageReducer';
import {
  consultantKnownAction,
  consultantVerificationKnownAction,
} from '../reducers/consultantReducer';
import { loadingEndAction, loadingStartAction } from '../reducers/loadingReducer';
import { hideNotificationAction, showNotificationAction } from '../reducers/notificationReducer';
import * as constant from '../utils/constant';
import debug from '../utils/debug';
import { mutate, query } from '../utils/graphql';
import { UPDATE_CONSULTANT_VERIFICATION } from '../utils/graphql/consultant/consultantVerification';
import { ADD_CONSULTANT, GET_COMPANIES_BY_MOST_JOB_REQUEST } from '../utils/graphqlStrings';

export const CONSULTANT_KNOWN = 'CONSULTANT_KNOWN';
export const CONSULTANT_VERIFICATION_KNOWN = 'CONSULTANT_VERIFICATION_KNOWN';

export const addConsultant = (): Function => async (dispatch: Function): Promise<void> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  dispatch(consultantKnownAction({ loaded: false }));

  const { data, error } = await mutate(ADD_CONSULTANT, { fetchPolicy: 'no-cache' });
  dispatch(loadingEndAction());

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: constant.CONSULTANT_ADD_FAIL }));
    return;
  }

  if (data?.addConsultant) {
    dispatch(consultantKnownAction(data.addConsultant));
    dispatch(showNotificationAction({ severity: 'success', message: '你已成为自由职业者' }));
    setTimeout(() => {
      navigate('/');
    }, 2000);
    return;
  }
};

export const updateConsultant = (consultant: Consultant): Function => async (
  dispatch: Function
): Promise<void> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  dispatch(consultantKnownAction({ loaded: false }));
  const { data, error } = await mutate('UPDATE_CONSULTANT', consultant, {
    fetchPolicy: 'no-cache',
  });
  dispatch(loadingEndAction());

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: constant.CONSULTANT_ADD_FAIL }));
    return;
  }
  if (data?.updateConsultant) {
    dispatch(consultantKnownAction(data.updateConsultant));
    navigate('/consultant');
    return;
  }
};

export const updateConsultantVerification: Function = (
  verification: ConsultantVerification
) => async (dispatch: Function): Promise<void> => {
  debug('Update consultant verification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = verification;
  const { data, loading, error } = await query(UPDATE_CONSULTANT_VERIFICATION, variables);
  if (!loading) {
    dispatch(loadingEndAction());
  }
  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: constant.CONSULTANT_VERIFICATION_UPDATE_FAIL,
      })
    );
    dispatch(loadingEndAction());
  }
  if (data && data?.consultantUpdateVerification) {
    const updatedVerification = data.consultantUpdateVerification;
    debug('Update consultant verification successfully');
    debug(updatedVerification);
    dispatch(consultantVerificationKnownAction(updatedVerification));
    dispatch(
      showNotificationAction({
        severity: 'success',
        message: '提交成功',
      })
    );
    updatedVerification.status === 'SUBMITTED' &&
      setTimeout(() => {
        navigate('/consultant');
      }, 1000);
  }
};

export const homePageInitialization: Function = () => async (dispatch: Function): Promise<void> => {
  debug('homePageInitialization');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  await dispatch(getJobRequestList());
  await dispatch(getHotCompanyList());
  dispatch(loadingEndAction());

  // const { data, error } = await query(GET_VERIFICATION);
  // if (error) {
  //   dispatch(
  //     showNotificationAction({
  //       severity: 'error',
  //       message: constant.CONSULTANT_VERIFICATION_GET_FAIL,
  //     })
  //   );
  //   dispatch(loadingEndAction());
  // }
  // if (data && data?.getCompanyVerificationByCompany) {
  //   const verification = data.getCompanyVerificationByCompany;
  //   debug('Get company verification successfully');
  //   debug(verification);
  //   dispatch(consultantVerificationKnownAction(verification));
  // }
};

export const getJobRequestList: Function = () => async (dispatch: Function): Promise<void> => {
  debug('Get JobRequestList');

  // TODO:
  // const { data, error } = await query(GET_VERIFICATION);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const test = new Promise<{ error?: any; data?: any }>((resolve, reject) => {
    setTimeout(function() {
      resolve({
        data: {
          getJobRequestList: {
            jobRequestList: Array.from({ length: 3 }).map((_, index) => {
              return {
                id: index.toString(),
                postType: `IT技术`,
                jobTitle: `前端开发工程师`,
                companyName: `Fintegrity信息技术有限公司${index}`,
                province: `辽宁省`,
                city: `大连市`,
                region: `高新园区`,
                workType: `全职`,
                industryTypeLv1: `行业一级`,
                industryTypeLv2: `行业二级`,
                workTimeLimit: `1-3年`,
                educationLimit: `本科及以上`,
                skillLimit: `TypeScript、React、Webpack、Nginx`,
              };
            }),
          },
        },
      });
    }, 1000);
  });
  const { data, error } = await test;
  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: constant.CONSULTANT_VERIFICATION_GET_FAIL,
      })
    );
  }
  if (data && data?.getJobRequestList) {
    const jobRequestList = data.getJobRequestList;
    debug('Get company jobRequestList successfully');
    debug(jobRequestList);
    dispatch(homePageJobRequestInfoKnownAction(jobRequestList));
  }
};

export const getHotCompanyList: Function = () => async (dispatch: Function): Promise<void> => {
  debug('Get HotCompanyList');

  // TODO:
  const variables = { n: 4 };
  const { data, error } = await query(GET_COMPANIES_BY_MOST_JOB_REQUEST, variables);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const test = new Promise<{ error?: any; data?: any }>((resolve, reject) => {
  //   setTimeout(function() {
  //     resolve({
  //       data: {
  //         getCompaniesByMostJobRequest: Array.from({ length: 4 }).map((_, index) => {
  //           return {
  //             id: index.toString(),
  //             companyName: `Fintegrity信息技术有限公司${index}`,
  //             requiredNumber: 123,
  //             companyLogo: testPic,
  //           };
  //         }),
  //       },
  //     });
  //   }, 1000);
  // });
  // const { data, error } = await test;
  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: constant.CONSULTANT_HOME_GET_HOT_COMPANY_FAIL,
      })
    );
  }
  if (data && data?.getCompaniesByMostJobRequest) {
    const hotCompanyList = {
      hotCompanyList: data.getCompaniesByMostJobRequest.map((data: any) => {
        // return { ...data, companyLogo: testPic };
        return data;
      }),
    };
    // .map((data: any) => {
    //   return { ...data, companyLogo: testPic };
    // });
    debug('Get company hotCompanyList successfully');
    // debug(hotCompanyList);
    dispatch(homePageCompanyListKnownAction(hotCompanyList));
  }
};

export const applyJobRequest: Function = (consultantId: number, jobRequestId: string) => async (
  dispatch: Function
): Promise<void> => {
  debug('consultant apply for jobRequest');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { consultantId, jobRequestId };
  // const { data, loading, error } = await query(DELETE_BASIC_FILE, variables);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const test = new Promise<{ error?: any; loading?: string; data?: any }>((resolve, reject) => {
    setTimeout(function() {
      variables;
      resolve({
        data: {
          applyJobRequest: {
            result: 'success',
          },
        },
        loading: 'loading',
      });
    }, 1000);
  });
  const { data, loading, error } = await test;
  if (!loading) {
    dispatch(loadingEndAction());
  }
  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: constant.CONSULTANT_VERIFICATION_UPDATE_FAIL,
      })
    );
    dispatch(loadingEndAction());
  }
  if (data && data?.applyJobRequest) {
    const applyJobRequest = data.applyJobRequest;
    debug('consultant apply for jobRequest successfully');
    debug(applyJobRequest);
    // TODO:
    // dispatch(consultantVerificationKnownAction(applyJobRequest));
    dispatch(
      showNotificationAction({
        severity: 'success',
        message: '提交成功',
      })
    );
    dispatch(loadingEndAction());
  }
};
