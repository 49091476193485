import { navigate } from 'gatsby';

import { adminKnownAction, AdminState } from '../reducers/adminReducer';
import { companyKnownAction, CompanyState } from '../reducers/companyReducer';
import { consultantKnownAction, ConsultantState } from '../reducers/consultantReducer';
import { loadingEndAction, loadingStartAction } from '../reducers/loadingReducer';
import { hideNotificationAction, showNotificationAction } from '../reducers/notificationReducer';
import {
  FUserTypeEnum,
  userKnownAction,
  userLogoutAction,
  userTokenKnowAction,
  userTypeKnownAction,
} from '../reducers/userReducer';
import { apiVersionKnownAction } from '../reducers/versionReducer';
import * as constant from '../utils/constant';
import debug from '../utils/debug';
import { Exception } from '../utils/exception';
import { mutate, query, removeToken, TOKEN_KEY } from '../utils/graphql';
import {
  ACTIVATE_EMAIL,
  INIT,
  LOGIN,
  NEW_PASSWORD,
  REGISTER_EMAIL_AND_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_CODE,
  VERIFY_AUTH_CODE,
} from '../utils/graphqlStrings';
import { addCompany } from './companyActions';
import { addConsultant } from './consultantActions';
const navigateToLogin = (userType: FUserTypeEnum | string) => {
  if (userType) {
    navigate(`/consultant/login`);
  }
};

export const initialize: Function = (userType: FUserTypeEnum) => async (
  dispatch: Function
): Promise<void> => {
  debug('Initialize');
  localStorage.setItem('userType', userType);
  if (typeof localStorage === 'undefined') {
    debug('Browser does not support local storage');
    dispatch(
      showNotificationAction({
        severity: 'warning',
        message: '浏览器不支持Local Storage, 需要重新登录',
      })
    );
    navigateToLogin(userType);
    return;
  }

  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    // if (userType === FUserTypeEnum.CONSULTANT) {
    //   const url = window.location.href;
    //   console.log(url);

    //   if (url.includes('/consultant/login')) {
    //     navigateToLogin(userType);
    //   } else {
    //     navigate('/consultant/visitor');
    //   }
    // } else {
    //   debug('Token not found, redirect to login page');
    navigateToLogin(userType);
    // }
    return;
  }

  dispatch(userTokenKnowAction(token));
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const { data, error } = await query(INIT);

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: constant.USER_NOT_LOGIN }));
    localStorage.removeItem(TOKEN_KEY);
    dispatch(loadingEndAction());
    navigateToLogin(userType);
    return;
  }

  // Get user info
  if (data && data?.getApiVersion) {
    const apiVersion = data.getApiVersion;
    debug(`Get API version: ${apiVersion}`);
    dispatch(apiVersionKnownAction(apiVersion));
  }

  // Get user info
  let company: CompanyState = {};
  let consultant: ConsultantState = {};
  let admin: AdminState = { companyList: [] };
  if (data && data?.getUser) {
    const user = data.getUser;
    debug(`Get user: ${JSON.stringify(user, null, 2)}`);
    dispatch(userKnownAction(user));

    company = data.getUser.company;
    consultant = data.getUser.consultant;
    admin = data.getUser.admin;

    if (company) {
      debug(`Get company: ${JSON.stringify(company, null, 2)}`);
      dispatch(companyKnownAction({ ...company, loaded: true }));
    }

    if (consultant) {
      debug(`Get consultant: ${JSON.stringify(consultant, null, 2)}`);
      dispatch(consultantKnownAction({ ...consultant, loaded: true }));
    }

    if (admin) {
      debug(`Get admin: ${JSON.stringify(admin, null, 2)}`);
      dispatch(adminKnownAction({ ...admin, loaded: true }));
    }

    return;
  }
  // Stop loading in HomeLayout

  dispatch(
    showNotificationAction({
      severity: 'warning',
      message: '未获取到用户信息, 请重新登录',
    })
  );
  navigateToLogin(userType);
};

export const getUser: Function = () => async (dispatch: Function): Promise<User> => {
  const { data, error } = await query(INIT);

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: constant.USER_NOT_LOGIN }));
    localStorage.removeItem(TOKEN_KEY);
    dispatch(loadingEndAction());

    return {} as User;
  }
  // Get user info
  let company: CompanyState = {};
  let consultant: ConsultantState = {};
  let admin: AdminState = { companyList: [] };
  if (data && data?.getUser) {
    // dispatch(hideNotificationAction());
    const user = data.getUser;
    debug(`Get user: ${JSON.stringify(user, null, 2)}`);
    dispatch(userKnownAction(user));
    company = data.getUser.company;
    consultant = data.getUser.consultant;
    admin = data.getUser.admin;

    if (company) {
      debug(`Get company: ${JSON.stringify(company, null, 2)}`);
      dispatch(companyKnownAction({ ...company, loaded: true }));
    }

    if (consultant) {
      debug(`Get consultant: ${JSON.stringify(consultant, null, 2)}`);
      dispatch(consultantKnownAction({ ...consultant, loaded: true }));
    }

    if (admin) {
      debug(`Get admin: ${JSON.stringify(admin, null, 2)}`);
      dispatch(adminKnownAction({ ...admin, loaded: true }));
    }

    return data.getUser;
  }
  // Stop loading in HomeLayout
  return {} as User;
  dispatch(
    showNotificationAction({
      severity: 'warning',
      message: '未获取到用户信息, 请重新登录',
    })
  );
};

export const register: Function = (
  email: string,
  password: string,
  userType: FUserTypeEnum,
  originUrl: string,
  phone?: string,
  lastName?: string,
  firstName?: string
) => async (dispatch: Function): Promise<boolean> => {
  debug('Register');
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { email, password, userType, originUrl, phone, lastName, firstName };
  const { data, error } = await mutate(REGISTER_EMAIL_AND_PASSWORD, variables);
  dispatch(loadingEndAction());
  if (error && error.code === Exception.USER_EMAIL_EXIST) {
    dispatch(
      showNotificationAction({ severity: 'error', message: constant.REGISTER_EMAIL_EXIST_ERROR })
    );
    return false;
  }
  if (data?.registerEmailAndPassword) {
    dispatch(
      showNotificationAction({ severity: 'success', message: constant.REGISTER_EMAIL_SENT })
    );
    const { token } = data.registerEmailAndPassword;
    localStorage.setItem(TOKEN_KEY, token);
    dispatch(userTypeKnownAction(userType));
    return true;
  } else {
    dispatch(
      showNotificationAction({ severity: 'error', message: constant.REGISTER_EMAIL_SENT_ERROR })
    );
    return false;
  }
};

export const resetPassword: Function = (email: string) => async (
  dispatch: Function
): Promise<boolean> => {
  debug('Reset password');
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { email };
  const { data, error } = await mutate(RESET_PASSWORD, variables);
  dispatch(loadingEndAction());
  if (data?.resetPassword) {
    dispatch(
      showNotificationAction({ severity: 'success', message: '验证码已发送到您的邮箱' + email })
    );
    return true;
  }
  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: '无法找到“' + email + '”绑定的帐号。请输入备用邮箱或手机号码。',
      })
    );
    return true;
  }
  return false;
};

export const confirmVerificationCode: Function = (email: string, code: string) => async (
  dispatch: Function
): Promise<boolean> => {
  debug('Confirm verification code');
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { email, code };
  const { data, error } = await mutate(RESET_PASSWORD_CODE, variables);
  dispatch(loadingEndAction());
  if (data?.confirmVerificationCode) {
    dispatch(showNotificationAction({ severity: 'success', message: 'correct' }));
    return true;
  }
  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: '验证码错误',
      })
    );
    return true;
  }
  return false;
};

export const newPassword: Function = (email: string, password: string, code: string) => async (
  dispatch: Function
): Promise<boolean> => {
  debug('Change password');
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { email, password, code };
  const { data, error } = await mutate(NEW_PASSWORD, variables);
  dispatch(loadingEndAction());
  if (data?.changePassword) {
    return true;
  }
  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '更改新密码失败' }));
    return false;
  }
  return false;
};

export const activateEmail: Function = (
  email: string,
  secret: string,
  auth: string,
  userType: FUserTypeEnum,
  originUrl: string
) => async (dispatch: Function): Promise<void> => {
  debug('Activate email');
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { email, secret, auth, originUrl };
  // 注册信息获取token
  const { data } = await mutate(ACTIVATE_EMAIL, variables);
  dispatch(loadingEndAction());
  if (data?.activateEmail) {
    dispatch(userTokenKnowAction(data.activateEmail.token));
    switch (userType) {
      case FUserTypeEnum.COMPANY:
        dispatch(addCompany());
        debug('company register API');
        break;
      case FUserTypeEnum.CONSULTANT:
        dispatch(addConsultant());
        debug('consultant register API');
        break;
      default:
    }
  } else {
    dispatch(
      showNotificationAction({ severity: 'error', message: constant.REGISTER_EMAIL_VERIFY_ERROR })
    );
  }
};

export const login: Function = (
  username: string,
  password: string,
  userType: FUserTypeEnum
) => async (dispatch: Function): Promise<void> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { username, password };
  const { data, error } = await query(LOGIN, variables);
  dispatch(loadingEndAction());

  if (error) {
    switch (error.code) {
      case 0x010204:
        dispatch(
          showNotificationAction({
            severity: 'error',
            message: constant.USER_LOGIN_FAIL_INCORRECT_USERNAME_OR_PASSWORD,
          })
        );
        break;
      case 0x010206:
        dispatch(
          showNotificationAction({
            severity: 'error',
            message: constant.USER_LOGIN_FAIL_EMAIL_INACTIVE,
          })
        );
        break;
      default:
        dispatch(showNotificationAction({ severity: 'error', message: constant.USER_LOGIN_FAIL }));
    }
    return;
  }
  const { token } = data.login;
  localStorage.setItem(TOKEN_KEY, token);
  dispatch(userTypeKnownAction(userType));
  switch (userType) {
    case FUserTypeEnum.COMPANY: {
      // dispatch(userTypeKnownAction(FUserTypeEnum.COMPANY));

      dispatch(showNotificationAction({ severity: 'success', message: '登录成功' }));

      navigate('/company');

      break;
    }
    case FUserTypeEnum.CONSULTANT: {
      // dispatch(userTypeKnownAction(FUserTypeEnum.CONSULTANT));
      const search = window.location.search || '';
      if (search) {
        const redirect = search.split('?redirect=')?.[1] || '';
        const requestUrl = decodeURIComponent(redirect).split('#');
        navigate(requestUrl[0] + '#' + requestUrl[1], {
          state: { row: requestUrl[1], bidRequest: true },
        });
      } else {
        dispatch(showNotificationAction({ severity: 'success', message: '登录成功' }));

        navigate('/');
      }
      break;
    }
    case FUserTypeEnum.ADMIN: {
      // dispatch(userTypeKnownAction(FUserTypeEnum.ADMIN));

      dispatch(showNotificationAction({ severity: 'success', message: '登录成功' }));

      navigate('/admin');

      break;
    }
    default:
      dispatch(
        showNotificationAction({ severity: 'error', message: constant.USER_TYPE_NOT_SPECIFIED })
      );
      navigate('/login');
  }
};

export const logout: Function = (userType: FUserTypeEnum) => (dispatch: Function): void => {
  removeToken();
  dispatch(userLogoutAction());
  localStorage.removeItem('userType');
  navigateToLogin(userType);
};

// export const sendAuthCode: Function = (username: string) => async (
//   dispatch: Function
// ): Promise<void> => {
//   dispatch(loadingStartAction());
//   dispatch(hideNotificationAction());
//   const variables = { username };
//   const { data, error } = await mutate(SEND_AUTH_CODE, variables);
//   dispatch(loadingEndAction());

//   if (error) {
//     dispatch(showNotificationAction({ severity: 'error', message: constant.AUTH_CODE_SENT_FAIL }));
//     return;
//   }
//   // dispatch(setResetEmail(data.sendAuthCode.data));
//   // dispatch(setAuthCodeSent(true));
// };

export const verifyAuthCode: Function = (username: string, authCode: string) => async (
  dispatch: Function
): Promise<void> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { username, authCode };
  const { data } = await query(VERIFY_AUTH_CODE, variables);
  dispatch(loadingEndAction());
  if (!data?.verifyAuthCode) {
    // dispatch(showNotificationAction('error', constant.VERIFY_CODE_FAILED));
    return;
  }
  // dispatch(setAuthCodeVerified());
};
