import { gql } from '@apollo/client';

const REGISTER_COMPANY = gql`
  mutation MUTATION($email: String!) {
    sendRegisterEmailAsCompany(email: $email) {
      data
    }
  }
`;

const LOGIN = gql`
  query QUERY($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
`;
// getUser删除name参数
const INIT = gql`
  query QUERY {
    getUser {
      id
      email
      emailActivated
      phone
      phoneActivated
      status
      admin {
        id
      }
      company {
        id
        deleted
        description
        verification {
          status
        }
      }
      consultant {
        id
        deleted
        description
        verification {
          status
          basicInfo {
            passportId
            lastName
            contactEmail
            firstName
            gender
          }
        }
      }
    }
  }
`;
const CHANGE_PASSWORD = gql`
  mutation MUTATION($originPassword: String!, $password: String!) {
    changePassword(originPassword: $originPassword, password: $password) {
      data
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation MUTATION($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;

const RESET_PASSWORD_CODE = gql`
  mutation MUTATION($email: String!, $code: String!) {
    resetPasswordCode(email: $email, code: $code)
  }
`;

const NEW_PASSWORD = gql`
  mutation MUTATION($email: String!, $password: String!, $code: String!) {
    newPassword(email: $email, password: $password, code: $code)
  }
`;

const SEND_AUTH_CODE = gql`
  mutation MUTATION($username: String!) {
    sendAuthCode(username: $username) {
      data
    }
  }
`;

const VERIFY_AUTH_CODE = gql`
  mutation verifyAuthCode($username: String!, $authCode: String!) {
    verifyAuthCode(username: $username, authCode: $authCode) {
      data
    }
  }
`;

const SEND_REGISTER_PASSCODE = gql`
  mutation sendRegisterPasscode($country: String!, $phone: String!) {
    sendRegisterPasscode(country: $country, phone: $phone)
  }
`;

const VERIFY_REGISTER_PASSCODE = gql`
  mutation verifyRegisterPasscode($passcode: String!, $phone: String!) {
    verifyRegisterPasscode(passcode: $passcode, phone: $phone)
  }
`;

const FINISH_REGISTER = gql`
  mutation finishRegister(
    $type: String!
    $phone: String!
    $email: String!
    $password: String!
    $province: String!
    $city: String!
  ) {
    finishRegister(
      type: $type
      phone: $phone
      email: $email
      password: $password
      province: $province
      city: $city
    ) {
      email
    }
  }
`;

const REGISTER_EMAIL_AND_PASSWORD = gql`
  mutation registerEmailAndPassword(
    $userType: FUserTypeEnum!
    $email: String!
    $password: String!
    $originUrl: String!
    $phone: String
    $firstName: String
    $lastName: String
  ) {
    registerEmailAndPassword(
      userType: $userType
      email: $email
      password: $password
      originUrl: $originUrl
      phone: $phone
      firstName: $firstName
      lastName: $lastName
    ) {
      token
    }
  }
`;

const ACTIVATE_EMAIL = gql`
  mutation activateEmail($email: String!, $secret: String!, $auth: String!, $originUrl: String!) {
    activateEmail(email: $email, secret: $secret, auth: $auth, originUrl: $originUrl) {
      token
    }
  }
`;

const RESEND_ACTIVE_EMAIL = gql`
  mutation resendActiveEmail($email: String!, $type: String!) {
    resendActiveEmail(email: $email, type: $type)
  }
`;

const ADD_COMPANY = gql`
  mutation MUTATION($description: String) {
    addCompany(description: $description) {
      id
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $id: String
    $contact: String
    $number: String
    $companyName: String!
    $province: String
    $city: String
    $workingAddress: String
    $registrationAddress: String
    $description: String
    $industry: String
  ) {
    updateCompany(
      id: $id
      registrationAddress: $registrationAddress
      province: $province
      city: $city
      companyName: $companyName
      contact: $contact
      number: $number
      workingAddress: $workingAddress
      description: $description
      industry: $industry
    ) {
      id
    }
  }
`;

const ADD_COMPANY_PROJECT_REQUEST = gql`
  mutation MUTATION($request: FProjectRequestInput) {
    companyAddProjectRequest(request: $request) {
      id
      company {
        id
        deleted
      }
      attachmentFileList {
        file
        id
      }
      projectCover
      status
      anonymous
      createTime
      workload
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      label
      professionalRequirements
      otherRequirements
      educationalRequirements
      projectStartAsap
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const UPDATE_COMPANY_PROJECT_REQUEST = gql`
  mutation MUTATION($request: FProjectRequestInput) {
    companyUpdateProjectRequest(request: $request) {
      anonymous
      attachmentFileList {
        file
        id
      }
      projectCover
      certificateInfoList {
        certificateDescription
        certificateName
        id
      }
      company {
        deleted
        description
        id
      }
      createTime
      label
      professionalRequirements
      otherRequirements
      educationalRequirements
      id
      industry
      languageInfoList {
        id
        languageLevel
        languageName
      }
      lastUpdateTime
      professionalClass
      projectContent
      projectEndTime
      projectName
      projectStartAsap
      projectStartTime
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      seniority
      status
      workLocationAddress
      workLocationCity
      workLocationDistrict
      workLocationProvince
      workScenario
      workload
    }
  }
`;

const GET_VERIFICATION_FILES = gql`
  query QUERY($companyId: Long!) {
    getRegistrationCertificateFileByCompany(companyId: $companyId)
    getLegalRepresentativeIdFileByCompany(companyId: $companyId)
  }
`;

const GET_LEGAL_REPRESENTATIVE_ID_FILE = gql`
  query QUERY($companyId: Long!) {
    getCompanyVerificationByCompany(companyId: $companyId) {
      legalRepresentativeIdFile
    }
  }
`;

const GET_REGISTRATION_CERTIFICATE_FILE = gql`
  query QUERY($companyId: Long!) {
    getCompanyVerificationByCompany(companyId: $companyId) {
      registrationCertificateFile
    }
  }
`;

const ADD_COMPANY_VERIFICATION = gql`
  mutation MUTATION($verification: FCompanyVerificationInput) {
    companyAddVerification(verification: $verification) {
      id
      status
      company {
        id
      }
      basicInfo {
        id
        avatar
        alias
        companyIndustry
        contactAddress
        contactEmail
        contactPhone
        enterpriseLegalPerson
        enterpriseSize
        registrationAddress
        registrationId
        registrationName
      }
      supplementInfo {
        id
        businessDescription
        certificateFileList {
          id
          file
        }
        demandDescription
        enterpriseWebsite
      }
    }
  }
`;

const UPDATE_COMPANY_VERIFICATION = gql`
  mutation MUTATION($verification: FCompanyVerificationInput) {
    companyUpdateVerification(verification: $verification) {
      id
      status
      company {
        id
      }
      basicInfo {
        id
        avatar
        alias
        companyIndustry
        contactAddress
        contactEmail
        contactPhone
        enterpriseLegalPerson
        enterpriseSize
        registrationAddress
        registrationId
        registrationName
      }
      supplementInfo {
        id
        businessDescription
        certificateFileList {
          id
          file
        }
        demandDescription
        enterpriseWebsite
      }
    }
  }
`;

const ADMIN_GET_ALL_COMPANY_VERIFICATION = gql`
  query QUERY {
    adminGetAllCompanyVerification {
      basicInfo {
        avatar
        companyIndustry
        contactAddress
        contactEmail
        contactPhone
        enterpriseLegalPerson
        enterpriseSize
        id
        registrationAddress
        registrationId
        registrationName
      }
      comment
      company {
        deleted
        description
        id
      }
      createTime
      description
      id
      lastUpdateTime
      status
      supplementInfo {
        businessDescription
        certificateFileList {
          id
          file
        }
        demandDescription
        enterpriseWebsite
        id
      }
    }
  }
`;

const ADMIN_APPROVE_COMPANY_VERIFICATION = gql`
  mutation MUTATION($verificationId: Long!) {
    adminApproveCompanyVerification(verificationId: $verificationId) {
      createTime
      description
      status
      id
    }
  }
`;

const ADMIN_DENY_COMPANY_VERIFICATION = gql`
  mutation MUTATION($verificationId: Long!, $comment: String) {
    adminDenyCompanyVerification(verificationId: $verificationId, comment: $comment) {
      createTime
      description
      status
      id
    }
  }
`;

const GET_COMPANY_VERIFICATION = gql`
  query QUERY {
    companyGetVerification {
      id
      status
      company {
        id
      }
      basicInfo {
        id
        alias
        avatar
        companyIndustry
        contactAddress
        contactEmail
        contactPhone
        enterpriseLegalPerson
        enterpriseSize
        registrationAddress
        registrationId
        registrationName
      }
      supplementInfo {
        id
        businessDescription
        certificateFileList {
          id
          file
        }
        demandDescription
        enterpriseWebsite
      }
    }
  }
`;

const GET_ALL_COMPANIES = gql`
  query QUERY {
    getAllCompanies {
      id
      status
      company {
        id
        companyName
        contact
        number
        industry
        province
        city
        registrationAddress
        workingAddress
        description
      }
    }
  }
`;

const GET_ALL_COMPANIES_VERIFICATION = gql`
  query QUERY {
    getAllCompanyVerifications {
      id
      registrationName
      registrationId
      registrationCertificateFile
      legalRepresentativeName
      legalRepresentativeType
      legalRepresentativeId
      legalRepresentativeIdFile
      description
    }
  }
`;

const GET_JOB_REQUESTS = gql`
  query QUERY($companyId: String) {
    getCompanyJobRequestsByCompany(companyId: $companyId) {
      basicInfo {
        id
        city
        description
        endTime
        nationality
        province
        region
        requestConsultantNumber
        startTime
        title
        workingAddress
      }
      company {
        id
      }
      detail {
        duration
        expectStartTime
        experience
        id
        jobRequirementList {
          id
          industry
          internationalCertificates
          jobTypeFirst
          jobTypeSecond
          nationalCertificates
        }
        languageList {
          id
          language
          listening
          speaking
          writing
        }
        settlePercent
        workPlace
        workType
        salaryMin
        salaryMax
      }
      id
      status
    }
  }
`;

const ADD_JOB_REQUEST = gql`
  mutation MUTATION(
    $companyId: String
    $basicInfo: FCompanyJobRequestBasicInfoInput
    $detail: FCompanyJobRequestDetailInput
  ) {
    addCompanyJobRequest(companyId: $companyId, basicInfo: $basicInfo, detail: $detail) {
      basicInfo {
        id
        city
        description
        endTime
        nationality
        province
        region
        requestConsultantNumber
        startTime
        title
        workingAddress
      }
      company {
        id
      }
      detail {
        duration
        expectStartTime
        salaryMin
        salaryMax
        experience
        id
        jobRequirementList {
          id
          industry
          internationalCertificates
          jobTypeFirst
          jobTypeSecond
          nationalCertificates
        }
        languageList {
          id
          language
          listening
          speaking
          writing
        }
        settlePercent
        workPlace
        workType
      }
      id
      status
    }
  }
`;

const UPDATE_JOB_REQUEST = gql`
  mutation MUTATION(
    $id: String
    $basicInfo: FCompanyJobRequestBasicInfoInput
    $detail: FCompanyJobRequestDetailInput
  ) {
    updateCompanyJobRequest(id: $id, basicInfo: $basicInfo, detail: $detail) {
      id
    }
  }
`;
const UPDATE_JOB_REQUEST_STATUS = gql`
  mutation MUTATION($id: String) {
    updateCompanyJobRequestStatus(id: $id) {
      id
      status
    }
  }
`;

const DELETE_JOB_REQUEST = gql`
  mutation MUTATE($id: String) {
    deleteCompanyJobRequest(id: $id)
  }
`;

const ADD_CONSULTANT = gql`
  mutation MUTATION($description: String) {
    addConsultant(description: $description) {
      deleted
      description
      id
      user {
        email
        firstName
        id
        lastName
        phone
      }
      verification {
        basicInfo {
          firstName
          lastName
          phoneNumber
        }
      }
    }
  }
`;

const DELETE_BASIC_FILE = gql`
  query QUERY($id: Long!, $verificationId: Long!) {
    deleteBasicFile(id: $id, verificationId: $verificationId) {
      id
      consultantType
      status
      basicInfo {
        id
        nationality
        regionProvince
        regionCity
        lastName
        firstName
        avatar
        phoneNumber
        gender
        birthday
        birthYear
        birthMonth
        passportType
        passportId
        personalDescriptionFileList {
          id
          file
        }
        contactEmail
        nowAddressProvince
        nowAddressCity
        nowAddressDetail
        nowAddressDistrict
        personalDescription
      }
      educationInfoList {
        id
        education
        schoolName
        majorType
        academicDirections
        atSchoolDateRangeStart
        atSchoolDateRangeEnd
        educationFileList {
          id
          file
        }
      }
      workInfoList {
        id
        companyName
        wayOfWorking
        jobTitle
        industryClass
        professionalClass
        atWorkDateRangeStart
        atWorkDateRangeEnd
        jobDescription
      }
      projectInfoList {
        id
        projectDateStart
        projectCompanyName
        projectImageFileList {
          id
          file
        }
        projectDateEnd
        projectName
        projectDescription
      }
      honorInfoList {
        id
        honorTitle
        honorDateRangeStart
        honorDateRangeEnd
        honorContent
      }
      certificateInfoList {
        certificateName
        certificateDescription
        certificateFileList {
          id
          file
        }
      }
    }
  }
`;

const DELETE_EDU_FILE = gql`
  query QUERY($id: String!, $verificationId: String!) {
    deleteEduFile(id: $id, verificationId: $verificationId) {
      id
      consultantType
      status
      educationInfoList {
        id
        education
        schoolName
        majorType
        academicDirections
        atSchoolDateRangeStart
        atSchoolDateRangeEnd
        educationFileList {
          id
          file
        }
      }
    }
  }
`;

const GET_COMPANIES_BY_MOST_JOB_REQUEST = gql`
  query QUERY($n: Int!) {
    getCompaniesByMostJobRequest(n: $n) {
      companyName
      id
      jobRequestNumber
    }
  }
`;

const GET_ALL_JOB_REQUESTS = gql`
  query QUERY {
    getAllJobRequests {
      id
      status
      company {
        companyName
      }
      basicInfo {
        title
        province
        city
        startTime
      }
      detail {
        workType
      }
    }
  }
`;
const ADMIN_APPROVE_PROJECT_REQUEST = gql`
  mutation MUTATION($requestId: Long!) {
    adminApproveProjectRequest(requestId: $requestId) {
      id
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
            companyIndustry
            contactAddress
            contactEmail
            contactPhone
            enterpriseLegalPerson
            enterpriseSize
            id
            registrationAddress
            registrationId
          }
          supplementInfo {
            businessDescription
            demandDescription
            enterpriseWebsite
            id
          }
        }
      }
      status
      createTime
      workload
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const ANONYMOUS_GET_PROJECT_REQUEST_BY_ID = gql`
  query QUERY($requestId: Long!) {
    anonymousGetProjectRequestById(requestId: $requestId) {
      id
      projectCover
      attachmentFileList {
        file
        id
      }
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
            alias
            companyIndustry
            contactAddress
            contactEmail
            contactPhone
            enterpriseLegalPerson
            enterpriseSize
            id
            registrationAddress
            registrationId
          }
          supplementInfo {
            businessDescription
            demandDescription
            enterpriseWebsite
            id
          }
        }
      }
      status
      anonymous
      projectStartAsap
      createTime
      workload
      label
      professionalRequirements
      otherRequirements
      educationalRequirements
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;
const ADMIN_GET_PROJECT_REQUEST_BY_ID = gql`
  mutation MUTATION($requestId: Long!) {
    adminGetProjectRequestById(requestId: $requestId) {
      id
      projectCover
      attachmentFileList {
        file
        id
      }
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
            alias
            companyIndustry
            contactAddress
            contactEmail
            contactPhone
            enterpriseLegalPerson
            enterpriseSize
            id
            registrationAddress
            registrationId
          }
          supplementInfo {
            businessDescription
            demandDescription
            enterpriseWebsite
            id
          }
        }
      }
      status
      anonymous
      projectStartAsap
      createTime
      workload
      label
      professionalRequirements
      otherRequirements
      educationalRequirements
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const CONSULTANT_GET_PROJECT_REQUEST_BY_ID = gql`
  query QUERY($requestId: Long!) {
    consultantGetProjectRequestById(requestId: $requestId) {
      id
      projectCover
      attachmentFileList {
        file
        id
      }
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
            alias
            companyIndustry
            contactAddress
            contactEmail
            contactPhone
            enterpriseLegalPerson
            enterpriseSize
            id
            registrationAddress
            registrationId
          }
          supplementInfo {
            businessDescription
            demandDescription
            enterpriseWebsite
            id
          }
        }
      }
      status
      anonymous
      projectStartAsap
      createTime
      workload
      label
      professionalRequirements
      otherRequirements
      educationalRequirements
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const GET_PROJECT_REQUEST_DETAILS = gql`
  query QUERY($requestId: Long!) {
    companyGetProjectRequestById(requestId: $requestId) {
      id
      projectCover
      attachmentFileList {
        file
        id
      }
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
            alias
            companyIndustry
            contactAddress
            contactEmail
            contactPhone
            enterpriseLegalPerson
            enterpriseSize
            id
            registrationAddress
            registrationId
          }
          supplementInfo {
            businessDescription
            demandDescription
            enterpriseWebsite
            id
          }
        }
      }
      status
      anonymous
      projectStartAsap
      createTime
      workload
      label
      professionalRequirements
      otherRequirements
      educationalRequirements
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const ADMIN_GET_All_PROJECT_REQUEST_BY_STATUS = gql`
  mutation MUTATION($status: FProjectRequestStatusEnum) {
    adminGetAllProjectRequestByStatus(status: $status) {
      id
      company {
        id
        deleted
      }
      status
      createTime
      workload
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const GET_PROJECT_REQUEST_ALL = gql`
  query QUERY {
    companyGetAllProjectRequest {
      id
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
            companyIndustry
            contactAddress
            contactEmail
            contactPhone
            enterpriseLegalPerson
            enterpriseSize
            id
            registrationAddress
            registrationId
          }
          supplementInfo {
            businessDescription
            demandDescription
            enterpriseWebsite
            id
          }
        }
      }
      status
      createTime
      workload
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const CONSULTANT_GET_ALL_PROJECT_REQUEST = gql`
  query QUERY {
    consultantGetAllProjectRequest {
      id
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
            companyIndustry
            contactAddress
            contactEmail
            contactPhone
            enterpriseLegalPerson
            enterpriseSize
            id
            registrationAddress
            registrationId
          }
          supplementInfo {
            businessDescription
            demandDescription
            enterpriseWebsite
            id
          }
        }
      }
      status
      createTime
      workload
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const ANONYMOUS_GET_ALL_PROJECT_REQUEST = gql`
  query QUERY {
    anonymousGetAllProjectRequest {
      id
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
            companyIndustry
            contactAddress
            contactEmail
            contactPhone
            enterpriseLegalPerson
            enterpriseSize
            id
            registrationAddress
            registrationId
          }
          supplementInfo {
            businessDescription
            demandDescription
            enterpriseWebsite
            id
          }
        }
      }
      status
      createTime
      workload
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      label
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const ANONYMOUS_GET_LAST_CREATED_PROJECT_REQUEST = gql`
  query QUERY($size: Int!) {
    anonymousGetLastCreatedProjectRequest(size: $size) {
      id
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
          }
        }
      }
      status
      createTime
      workload
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const ANONYMOUS_GET_LAST_UPDATE_PROJECT_REQUEST = gql`
  query QUERY($size: Int!) {
    anonymousGetLastUpdatedProjectRequest(size: $size) {
      id
      company {
        id
        deleted
        verification {
          basicInfo {
            registrationName
            avatar
          }
        }
      }
      status
      createTime
      workload
      workScenario
      workLocationProvince
      workLocationDistrict
      workLocationCity
      workLocationAddress
      seniority
      remunerationInfo {
        id
        monthlySalaryDescription
        monthlySalaryMax
        monthlySalaryMin
        otherSalaryCycle
        otherSalaryDescription
        singleSalaryDescription
        singleSalaryMax
        singleSalaryMin
      }
      projectStartTime
      projectName
      projectEndTime
      projectContent
      professionalClass
      lastUpdateTime
      languageInfoList {
        id
        languageLevel
        languageName
      }
      industry
      certificateInfoList {
        certificateName
        certificateDescription
      }
    }
  }
`;

const ADD_CONSULTANT_BIDING_PROJECT = gql`
  mutation MUTATION($bid: FProjectBidInput) {
    consultantAddProjectBid(bid: $bid) {
      companyBidStatus
      consultant {
        id
        verification {
          basicInfo {
            contactEmail
            firstName
            lastName
          }
        }
      }
      request {
        id
        projectStartTime
        projectName
        projectEndTime
        company {
          id
          deleted
        }
        remunerationInfo {
          id
          monthlySalaryDescription
          monthlySalaryMax
          monthlySalaryMin
          otherSalaryCycle
          otherSalaryDescription
          singleSalaryDescription
          singleSalaryMax
          singleSalaryMin
        }
      }
      consultantBidAdvantages
      consultantBidStatus
      createTime
      id
    }
  }
`;

const GET_COMPANY_GET_ALL_PROJECT_BID_NOT_AVATAR = gql`
  query QUERY {
    companyGetAllProjectBid {
      bidSalary
      companyBidComment
      companyBidStatus
      consultant {
        id
        verification {
          basicInfo {
            nowAddressCity
            nowAddressDistrict
            nowAddressProvince
            nowAddressDetail
            firstName
            lastName
            gender
            birthday
          }
        }
      }
      consultantBidAdvantages
      consultantBidStatus
      createTime
      id
      lastUpdateTime
      offer {
        companyComment
        consultantComment
        contactInfo
        createTime
        id
        lastUpdateTime
        offerContent
        offerName
        projectEndTime
        projectRemuneration
        projectStartTime
        status
        workLocationAddress
        workLocationCity
        workLocationDistrict
        workLocationProvince
        workScenario
        workload
      }
      request {
        createTime
        id
        industry
        lastUpdateTime
        professionalClass
        projectContent
        projectEndTime
        projectName
        projectStartAsap
        projectStartTime
      }
    }
  }
`;

const GET_COMPANY_GET_ALL_PROJECT_BID = gql`
  query QUERY {
    companyGetAllProjectBid {
      bidSalary
      companyBidComment
      companyBidStatus
      consultant {
        id
        verification {
          basicInfo {
            nowAddressCity
            nowAddressDistrict
            nowAddressProvince
            nowAddressDetail
            firstName
            avatar
            lastName
            gender
            birthday
          }
        }
      }
      consultantBidAdvantages
      consultantBidStatus
      createTime
      id
      lastUpdateTime
      offer {
        companyComment
        consultantComment
        contactInfo
        createTime
        id
        lastUpdateTime
        offerContent
        offerName
        projectEndTime
        projectRemuneration
        projectStartTime
        status
        workLocationAddress
        workLocationCity
        workLocationDistrict
        workLocationProvince
        workScenario
        workload
      }
      request {
        createTime
        id
        industry
        lastUpdateTime
        professionalClass
        projectContent
        projectEndTime
        projectName
        projectStartAsap
        projectStartTime
      }
    }
  }
`;
const GET_CONSULTANT_ALL_PROJECT = gql`
  query QUERY {
    consultantGetAllProject {
      companyComment
      consultantComment
      createTime
      id
      lastUpdateTime
      offer {
        companyComment
        consultantComment
        contactInfo
        createTime
        id
        lastUpdateTime
        offerContent
        offerName
        projectEndTime
        projectRemuneration
        projectStartTime
        status
        workLocationAddress
        workLocationCity
        workLocationDistrict
        workLocationProvince
        workScenario
        workload
      }
      projectSettlement {
        amount
        description
        id
        settlementTime
        status
      }
      status
      suspendStatus
    }
  }
`;
const GET_COMPANY_ALL_PROJECT = gql`
  query QUERY {
    companyGetAllProject {
      companyComment
      consultantComment
      createTime
      id
      lastUpdateTime
      offer {
        bid {
          consultant {
            verification {
              basicInfo {
                firstName
                lastName
              }
            }
          }
        }
        companyComment
        consultantComment
        contactInfo
        createTime
        id
        lastUpdateTime
        offerContent
        offerName
        projectEndTime
        projectRemuneration
        projectStartTime
        status
        workLocationAddress
        workLocationCity
        workLocationDistrict
        workLocationProvince
        workScenario
        workload
      }
      projectSettlement {
        amount
        description
        id
        settlementTime
        status
      }
      status
      suspendStatus
    }
  }
`;

const GET_CONSULTANT_GET_ALL_PROJECT_BID = gql`
  query QUERY {
    consultantGetAllProjectBid {
      attachments {
        file
        id
      }
      bidSalary
      companyBidComment
      companyBidStatus
      consultant {
        id
        verification {
          basicInfo {
            contactEmail
            firstName
            lastName
          }
        }
      }
      consultantBidAdvantages
      consultantBidStatus
      createTime
      endDate
      id
      lastUpdateTime
      offer {
        companyComment
        consultantComment
        contactInfo
        createTime
        id
        lastUpdateTime
        offerContent
        offerName
        projectEndTime
        projectRemuneration
        projectStartTime
        status
        workLocationAddress
        workLocationCity
        workLocationDistrict
        workLocationProvince
        workScenario
        workload
      }
      request {
        createTime
        id
        industry
        lastUpdateTime
        professionalClass
        projectContent
        projectEndTime
        projectName
        projectStartAsap
        projectStartTime
        workLocationProvince
        workLocationCity
        workLocationDistrict
        workLocationAddress
        remunerationInfo {
          id
          monthlySalaryDescription
          monthlySalaryMax
          monthlySalaryMin
          otherSalaryCycle
          otherSalaryDescription
          singleSalaryDescription
          singleSalaryMax
          singleSalaryMin
        }
      }
      startAsap
      startDate
    }
  }
`;

const GET_CONSULTANT_GET_ALL_PROJECT_BID_ONLY_REQUEST_ID = gql`
  query QUERY {
    consultantGetAllProjectBid {
      request {
        id
      }
    }
  }
`;

const GET_CONSULTANT_GET_PROJECT_BID_BY_ID = gql`
  query QUERY($bidId: Long!) {
    consultantGetProjectBidById(bidId: $bidId) {
      attachments {
        file
        id
      }
      bidSalary
      companyBidComment
      companyBidStatus
      consultant {
        id
        verification {
          basicInfo {
            contactEmail
            firstName
            lastName
            gender
            birthday
          }
        }
      }
      consultantBidAdvantages
      consultantBidStatus
      createTime
      endDate
      id
      lastUpdateTime
      request {
        id
        company {
          id
          verification {
            basicInfo {
              contactEmail
              registrationName
              contactAddress
            }
          }
        }
        status
        createTime
        workload
        workScenario
        workLocationProvince
        workLocationDistrict
        workLocationCity
        workLocationAddress
        seniority
        remunerationInfo {
          id
          monthlySalaryDescription
          monthlySalaryMax
          monthlySalaryMin
          otherSalaryCycle
          otherSalaryDescription
          singleSalaryDescription
          singleSalaryMax
          singleSalaryMin
        }
        projectStartAsap
        projectStartTime
        projectName
        projectEndTime
        projectContent
        professionalClass
        lastUpdateTime
        languageInfoList {
          id
          languageLevel
          languageName
        }
        industry
        certificateInfoList {
          certificateName
          certificateDescription
        }
      }
      startAsap
      startDate
    }
  }
`;
const CONSULTANT_UPDATE_PROJECT_BID = gql`
  mutation MUTATION($bid: FProjectBidInput) {
    consultantUpdateProjectBid(bid: $bid) {
      attachments {
        file
        id
      }
      bidSalary
      companyBidComment
      companyBidStatus
      consultant {
        id
        verification {
          basicInfo {
            contactEmail
            firstName
            lastName
          }
        }
      }
      consultantBidAdvantages
      consultantBidStatus
      createTime
      endDate
      id
      lastUpdateTime
      request {
        id
        projectStartTime
        projectContent
        projectName
        projectEndTime
        company {
          id
          deleted
        }
        remunerationInfo {
          id
          monthlySalaryDescription
          monthlySalaryMax
          monthlySalaryMin
          otherSalaryCycle
          otherSalaryDescription
          singleSalaryDescription
          singleSalaryMax
          singleSalaryMin
        }
      }
      startAsap
      startDate
    }
  }
`;

const COMPANY_GET_PROJECT_BID_BY_ID = gql`
  query QUERY($bidId: Long!) {
    companyGetProjectBidById(bidId: $bidId) {
      companyBidStatus
      companyBidComment
      attachments {
        file
        id
      }
      offer {
        companyComment
        consultantComment
        contactInfo
        createTime
        id
        lastUpdateTime
        offerContent
        offerName
        projectEndTime
        projectRemuneration
        projectStartTime
        status
        workLocationAddress
        workLocationCity
        workLocationDistrict
        workLocationProvince
        workScenario
        workload
      }
      consultant {
        id
        verification {
          id
          consultantType
          status
          basicInfo {
            id
            avatar
            nationality
            regionProvince
            regionCity
            firstName
            lastName
            phoneNumber
            birthday
            gender
            birthYear
            birthMonth
            passportType
            passportId
            contactEmail
            nowAddressProvince
            nowAddressCity
            nowAddressDetail
            nowAddressDistrict
            personalDescription
          }
          educationInfoList {
            id
            education
            schoolName
            majorType
            academicDirections
            atSchoolDateRangeStart
            atSchoolDateRangeEnd
            educationFileList {
              id
              file
            }
          }
          workInfoList {
            id
            companyName
            wayOfWorking
            jobTitle
            industryClass
            professionalClass
            atWorkDateRangeStart
            atWorkDateRangeEnd
            jobDescription
          }
          projectInfoList {
            id
            projectDateStart
            projectCompanyName
            projectImageFileList {
              id
              file
            }
            projectDateEnd
            projectName
            projectDescription
          }
          languageInfoList {
            id
            languageLevel
            languageName
          }
          honorInfoList {
            id
            honorTitle
            honorDateRangeStart
            honorDateRangeEnd
            honorContent
          }
          certificateInfoList {
            certificateName
            certificateDescription
            certificateFileList {
              id
              file
            }
          }
          otherInfo {
            id
            background
            description
            strength
            resume
          }
          settings {
            findingJob
            id
            workDossier
            workType
            workScenario
            expectedFeePerHour
            expectedFeePerMonth
          }
        }
      }
      request {
        id
        company {
          id
          verification {
            basicInfo {
              registrationName
              avatar
              companyIndustry
              contactAddress
              contactEmail
              contactPhone
              enterpriseLegalPerson
              enterpriseSize
              id
              registrationAddress
              registrationId
            }
            supplementInfo {
              businessDescription
              demandDescription
              enterpriseWebsite
              id
            }
          }
        }
        status
        createTime
        workload
        workScenario
        workLocationProvince
        workLocationDistrict
        workLocationCity
        workLocationAddress
        seniority
        remunerationInfo {
          id
          monthlySalaryDescription
          monthlySalaryMax
          monthlySalaryMin
          otherSalaryCycle
          otherSalaryDescription
          singleSalaryDescription
          singleSalaryMax
          singleSalaryMin
        }
        projectStartTime
        projectStartAsap
        projectName
        projectEndTime
        projectContent
        professionalClass
        lastUpdateTime
        languageInfoList {
          id
          languageLevel
          languageName
        }
        industry
        certificateInfoList {
          certificateName
          certificateDescription
        }
      }
      bidSalary
      consultantBidAdvantages
      consultantBidStatus
      createTime
      id
      startDate
      startAsap
      lastUpdateTime
      endDate
    }
  }
`;

const COMPANY_UPDATE_PROJECT_BID_STATUS = gql`
  query QUERY($bidId: Long!, $status: FCompanyProjectBidStatusEnum) {
    companyUpdateProjectBidStatus(bidId: $bidId, status: $status) {
      companyBidStatus
      consultant {
        id
        verification {
          basicInfo {
            contactEmail
            firstName
            lastName
          }
        }
      }
      request {
        id
        projectStartTime
        projectName
        projectEndTime
        company {
          id
          deleted
        }
        remunerationInfo {
          id
          monthlySalaryDescription
          monthlySalaryMax
          monthlySalaryMin
          otherSalaryCycle
          otherSalaryDescription
          singleSalaryDescription
          singleSalaryMax
          singleSalaryMin
        }
      }
      consultantBidAdvantages
      consultantBidStatus
      createTime
      id
    }
  }
`;
const COMPANY_GET_ALL_PROJECT_OFFER = gql`
  query QUERY {
    companyGetAllProjectOffer {
      bid {
        bidSalary
        companyBidComment
        companyBidStatus
        consultant {
          id
          verification {
            basicInfo {
              contactEmail
              firstName
              lastName
            }
          }
        }
        consultantBidAdvantages
        consultantBidStatus
        createTime
        endDate
        id
        lastUpdateTime
        request {
          id
          projectStartTime
          projectName
          projectEndTime
          company {
            id
            deleted
          }
          remunerationInfo {
            id
            monthlySalaryDescription
            monthlySalaryMax
            monthlySalaryMin
            otherSalaryCycle
            otherSalaryDescription
            singleSalaryDescription
            singleSalaryMax
            singleSalaryMin
          }
        }
        startAsap
        startDate
      }
      contactInfo
      createTime
      id
      lastUpdateTime
      offerContent
      offerName
      projectEndTime
      projectRemuneration
      projectStartTime
      status
      workLocationAddress
      workLocationCity
      workLocationDistrict
      workLocationProvince
      workScenario
      workload
    }
  }
`;

const COMPANY_ADD_PROJECT_OFFER = gql`
  mutation MUTATION($offer: FProjectOfferInput) {
    companyAddProjectOffer(offer: $offer) {
      bid {
        bidSalary
        companyBidComment
        companyBidStatus
        consultantBidAdvantages
        consultantBidStatus
        createTime
        endDate
        id
        lastUpdateTime
        startAsap
        startDate
      }
      companyComment
      consultantComment
      contactInfo
      createTime
      id
      lastUpdateTime
      offerContent
      offerName
      project {
        companyComment
        consultantComment
        createTime
        id
        lastUpdateTime
        status
        suspendStatus
      }
      projectEndTime
      projectRemuneration
      projectStartTime
      status
      workLocationAddress
      workLocationCity
      workLocationDistrict
      workLocationProvince
      workScenario
      workload
    }
  }
`;
const COMPANY_GET_PROJECT_OFFER_BY_ID = gql`
  query QUERY($offerId: Long!) {
    companyGetProjectOfferById(offerId: $offerId) {
      bid {
        bidSalary
        companyBidComment
        companyBidStatus
        consultant {
          id
          verification {
            basicInfo {
              contactEmail
              firstName
              lastName
              gender
              birthday
            }
          }
        }
        consultantBidAdvantages
        consultantBidStatus
        createTime
        endDate
        id
        lastUpdateTime
        request {
          createTime
          id
          industry
          lastUpdateTime
          professionalClass
          projectContent
          projectEndTime
          projectName
          projectStartTime
        }
        startAsap
        startDate
      }
      contactInfo
      createTime
      id
      lastUpdateTime
      offerContent
      offerName
      projectEndTime
      projectRemuneration
      projectStartTime
      status
      workLocationAddress
      workLocationCity
      workLocationDistrict
      workLocationProvince
      workScenario
      workload
    }
  }
`;
const CONSULTANT_DELETE_PROJECT_BID_BY_ID = gql`
  mutation MUTATION($bidId: Long!) {
    consultantDeleteProjectBidById(bidId: $bidId)
  }
`;
const CONSULTANT_GET_PROJECT_OFFER_BY_ID = gql`
  query QUERY($offerId: Long!) {
    consultantGetProjectOfferById(offerId: $offerId) {
      bid {
        bidSalary
        companyBidComment
        companyBidStatus
        consultantBidAdvantages
        consultantBidStatus
        createTime
        endDate
        id
        lastUpdateTime
        startAsap
        startDate
      }
      companyComment
      consultantComment
      contactInfo
      createTime
      id
      lastUpdateTime
      offerContent
      offerName
      projectEndTime
      projectRemuneration
      projectStartTime
      status
      workLocationAddress
      workLocationCity
      workLocationDistrict
      workLocationProvince
      workScenario
      workload
    }
  }
`;

const CONSULTANT_ACCEPT_PROJECT_OFFER = gql`
  query QUERY($offerId: Long!, $comment: String) {
    consultantAcceptProjectOffer(offerId: $offerId, comment: $comment) {
      bid {
        bidSalary
        companyBidComment
        companyBidStatus
        consultantBidAdvantages
        consultantBidStatus
        createTime
        endDate
        id
        lastUpdateTime
        startAsap
        startDate
      }
      companyComment
      consultantComment
      contactInfo
      createTime
      id
      lastUpdateTime
      offerContent
      offerName
      projectEndTime
      projectRemuneration
      projectStartTime
      status
      workLocationAddress
      workLocationCity
      workLocationDistrict
      workLocationProvince
      workScenario
      workload
    }
  }
`;

const CONSULTANT_REJECT_PROJECT_OFFER = gql`
  query QUERY($offerId: Long!, $comment: String) {
    consultantRejectProjectOffer(offerId: $offerId, comment: $comment) {
      bid {
        bidSalary
        companyBidComment
        companyBidStatus
        consultantBidAdvantages
        consultantBidStatus
        createTime
        endDate
        id
        lastUpdateTime
        startAsap
        startDate
      }
      companyComment
      consultantComment
      contactInfo
      createTime
      id
      lastUpdateTime
      offerContent
      offerName
      projectEndTime
      projectRemuneration
      projectStartTime
      status
      workLocationAddress
      workLocationCity
      workLocationDistrict
      workLocationProvince
      workScenario
      workload
    }
  }
`;

export {
  REGISTER_COMPANY,
  LOGIN,
  INIT,
  ANONYMOUS_GET_LAST_CREATED_PROJECT_REQUEST,
  ANONYMOUS_GET_LAST_UPDATE_PROJECT_REQUEST,
  COMPANY_ADD_PROJECT_OFFER,
  COMPANY_GET_PROJECT_OFFER_BY_ID,
  COMPANY_GET_ALL_PROJECT_OFFER,
  CHANGE_PASSWORD,
  SEND_AUTH_CODE,
  VERIFY_AUTH_CODE,
  SEND_REGISTER_PASSCODE,
  VERIFY_REGISTER_PASSCODE,
  ANONYMOUS_GET_ALL_PROJECT_REQUEST,
  CONSULTANT_GET_PROJECT_REQUEST_BY_ID,
  CONSULTANT_GET_ALL_PROJECT_REQUEST,
  FINISH_REGISTER,
  REGISTER_EMAIL_AND_PASSWORD,
  ACTIVATE_EMAIL,
  RESEND_ACTIVE_EMAIL,
  ADD_COMPANY,
  UPDATE_COMPANY,
  GET_VERIFICATION_FILES,
  GET_LEGAL_REPRESENTATIVE_ID_FILE,
  GET_REGISTRATION_CERTIFICATE_FILE,
  ADD_COMPANY_VERIFICATION,
  GET_ALL_COMPANIES_VERIFICATION,
  UPDATE_COMPANY_VERIFICATION,
  GET_ALL_COMPANIES,
  GET_JOB_REQUESTS,
  ADD_JOB_REQUEST,
  UPDATE_JOB_REQUEST,
  UPDATE_JOB_REQUEST_STATUS,
  ADMIN_DENY_COMPANY_VERIFICATION,
  DELETE_JOB_REQUEST,
  ADD_CONSULTANT,
  RESET_PASSWORD,
  RESET_PASSWORD_CODE,
  NEW_PASSWORD,
  DELETE_BASIC_FILE,
  DELETE_EDU_FILE,
  ADMIN_GET_ALL_COMPANY_VERIFICATION,
  GET_COMPANIES_BY_MOST_JOB_REQUEST,
  GET_ALL_JOB_REQUESTS,
  GET_COMPANY_VERIFICATION,
  ADD_COMPANY_PROJECT_REQUEST,
  GET_PROJECT_REQUEST_ALL,
  ADMIN_GET_PROJECT_REQUEST_BY_ID,
  GET_PROJECT_REQUEST_DETAILS,
  ADMIN_GET_All_PROJECT_REQUEST_BY_STATUS,
  ADMIN_APPROVE_PROJECT_REQUEST,
  ANONYMOUS_GET_PROJECT_REQUEST_BY_ID,
  ADD_CONSULTANT_BIDING_PROJECT,
  GET_CONSULTANT_GET_ALL_PROJECT_BID,
  GET_COMPANY_GET_ALL_PROJECT_BID,
  GET_CONSULTANT_GET_PROJECT_BID_BY_ID,
  CONSULTANT_UPDATE_PROJECT_BID,
  COMPANY_GET_PROJECT_BID_BY_ID,
  COMPANY_UPDATE_PROJECT_BID_STATUS,
  CONSULTANT_DELETE_PROJECT_BID_BY_ID,
  UPDATE_COMPANY_PROJECT_REQUEST,
  ADMIN_APPROVE_COMPANY_VERIFICATION,
  GET_COMPANY_GET_ALL_PROJECT_BID_NOT_AVATAR,
  GET_CONSULTANT_ALL_PROJECT,
  GET_COMPANY_ALL_PROJECT,
  CONSULTANT_ACCEPT_PROJECT_OFFER,
  CONSULTANT_REJECT_PROJECT_OFFER,
  CONSULTANT_GET_PROJECT_OFFER_BY_ID,
  GET_CONSULTANT_GET_ALL_PROJECT_BID_ONLY_REQUEST_ID,
};
